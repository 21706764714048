.front-frame {
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: #e0e6ec;
}
  
.frame-10 {
    color: #000;
    width: 90%;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 25px;
    font-family: 'Inder';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 30px;
    display: flex;
}

.graduation-cap-svgre {
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-top: 5px;
    margin-left: 5px;
}

.cmuda {
    margin-left: 1px;
    font-family: 'Inder';
    font-size: 3rem;
    line-height: 58px;
}





.to-run-the-mscs-degr {
    font-family: 'Inder';
    text-align: center;
    color: #000000e0;
    margin-top: 7px;
    margin-left: 1px;
    font-size: 18px;
}
.only {
    font-family: 'Inder';
    text-align: center;
    color: #043964e0;
    margin-top: 0px;
    margin-left: 1px;
    font-size: 14px;
}
.component-6-instance-1 {
    width: 20%;
    flex-basis: 5%;
    margin-bottom: 106px;
    margin-left: 7px;
}

.component-3-instance-1 {
    text-align: center;
    color: #ffffff;
    background-color: #527dec;
    border: 0;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    height: 35px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 18px;
    font-weight: 400;
}

.drop_menu {
    min-width: 250px;
    width: 20%;
    font-size: 18px;
    font-family: 'Inder';
}