.Breadth{
    background-color: #EE6C4D;
    margin-left: 0px;
    margin-right: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 8px;
    padding-left: 8px;
    border-radius: 3px 3px 3px 3px;
    white-space: nowrap;
    font-size: 10px;
}

.Track{
    background-color: #6A9289;
    margin-left: 0px;
    margin-right: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 8px;
    padding-left: 8px;
    border-radius: 3px 3px 3px 3px;
    white-space: nowrap;
    font-size: 10px;
}

.Elective{
    background-color: #98C1D9;
    margin-left: 0px;
    margin-right: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 8px;
    padding-left: 8px;
    border-radius: 3px 3px 3px 3px;
    white-space: nowrap;
    font-size: 10px;
}

.plan{
    box-shadow: 1px 1px 1px rgb(201, 201, 201);
    background-color: #E0E6EC;
    width: 160px;
    margin-left: 0px;
    margin-right: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 8px;
    padding-left: 12px;
    border-radius: 3px 3px 3px 3px;
}