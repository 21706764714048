.main_container2{
    color: #fff;
    
    width: 100%;
    line-height: 20px;
    background-color: white;
    border-radius: 5px 5px 5px 5px;
    align-items: center;
    padding-bottom: 8px;
    margin-top: 0px;
    padding-top: 0px;
    padding-left: 0px;
    
    display: flex;
    justify-content: space-between;
}

.left2{
    width: 5%;
    min-width: 40px;
    margin-right: 0px;
    background-color: white;
    align-self: flex-start;
}

.right2{
    color: black;
    font-size: 20px;
    width: 95%;
    background-color: white;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    
}
.title_s2{
    white-space: normal;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    margin-bottom: 1px;
    
}
.details2{
    display: flex;
    
}
.stats_s2_NO{
    color: red;
    width:  35px;
    border-color: red;
    border-width: 2px;
    border-style: solid;
    padding-top: 2px;
    padding-bottom: 2px;
    
    border-radius: 5px 5px 5px 5px;
    
    margin-right: 4px;
    margin-left: 0px;
    line-height: 12px;
    font-size: 12px;
    font-family: "Inder";
    text-align: center;
}

.stats_s2_OK{
    color: #33ce71;
    width:  35px;
    border-color: #33ce71;
    border-width: 2px;
    border-style: solid;
    padding-top: 2px;
    padding-bottom: 2px;
    
    border-radius: 5px 5px 5px 5px;
    
    margin-right: 4px;
    margin-left: 0px;
    line-height: 12px;
    font-size: 12px;
    font-family: "Inder";
    text-align: center;
}

.stats_s2_IP{
    color: #FF981F;
    width:  35px;
    border-color: #FF981F;
    border-width: 2px;
    border-style: solid;
    padding-top: 2px;
    padding-bottom: 2px;
    
    border-radius: 5px 5px 5px 5px;
    
    margin-right: 4px;
    margin-left: 0px;
    line-height: 12px;
    font-size: 12px;
    font-family: "Inder";
    text-align: center;
}

.link_color{
    color: #3d65bd;
}
/* border-color: red;
border-width: 2px;
border-style: solid ; */