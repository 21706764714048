.component-2 {
  color: #000;
  text-align: center;
  background-color: #c3d1de;
  border: 1px solid #527dec;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 1px;
  font-family: 'Inder';
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 30px;
  display: flex;
}

.next {
  margin-top: 1px;
}

.component-2:hover {
  cursor: pointer;
}
