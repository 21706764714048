.course_frame_mini{
    background-color: white;
    justify-content: space-between;
    border-radius: 5px 5px 5px 5px;
    margin-bottom: 10px;
    width: 100%;
    /* min-width: 210px;
    max-width: 210px; */
    min-width: 205px;
    max-width: 250px;
    color: white;
    font-family: "Inder";
    flex-direction: row;
    display: flex;
}
.main_course_frame_mini{
    color: black;
    padding: 10px 10px 10px 10px;
    
}
.tags{
    
    margin-top: 3px;
    margin-bottom: 3px;
    flex-direction: row;
    display: flex;
}
.course_title_mini{
    font-size: 16px;
    padding: 0px 0px 0px 0px;
}
.course_name{
    font-size: 14px;
}

.picked{
    color: #527dec;
    width: 24.8px;
    height: 100%;
    background-color: #527dec;
    border-radius: 12px 5px 5px 12px;
    display: flex;
    align-self:flex-end;
}

.notpicked{
    color: white;
}