.front-frame {
    background-color: #C3D1DE;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    flex-direction: row;
    display: flex;
}
.col-1{
    min-width: 200px;
}
.right-frame-plan {
    color: #234081;
    min-height: 80%;
    width: 100%;
    background-color: #C3D1DE;
    flex-direction: column;
    
    align-items: center;
    padding-top: 5px;
    padding-left: 5px;
    font-family: "Inder";
    padding-bottom: 15px;
    
    display: flex;
    background-color: #C3D1DE;
    
}

.two-buttons {
    height: 10%;

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: #C3D1DE;
    min-height: 60px;
}

.buttom-row{
    height: 0%; 
    background-color: #C3D1DE;
    display: flex;
}
.plan-button {
    text-align: center;
    color: #ffffff;
    background-color: #527dec;
    border: 0;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    height: 35px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 18px;
    font-weight: 400;
}
.front-frame::-webkit-scrollbar {
    display: none;
}
.big-container-plan {
    
    min-height: 100%;
    
}



.conclusion {
    height: 20%; 
    background-color: rgb(142, 241, 28);
    display: flex;
}


.plan_header{
    text-align: center;
    padding-top: 4px;
    color: #ffffff;
    height: 5%;
    width: 100%;
    background-color: #527dec;
    font-size: 18px;
    margin-bottom: 15px;
}