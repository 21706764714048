.main_container{
    color: #fff;
    
    width: 100%;
    line-height: 60px;
    background-color: rgb(255, 255, 255);
    border-radius: 6px 6px 6px 6px;
    align-items: center;
    margin-bottom: 0px;
    margin-top: 5px;
    padding-top: 0px;
    padding-left: 0px;
    font-size: 1.5rem;
    display: flex;
    justify-content: space-between;
}

.left{
    width: 10%;
    background-color: white;
    align-self: flex-start;
    min-width: 50px;
    margin-right: 5px;
    margin-left: 4px;
}

.right{
    color: black;
    font-size: 20px;
    width: 90%;
    background-color: white;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
}
.title_s{
    white-space: pre-line;
    line-height: 25px;
    margin-top: 2px;
    margin-bottom: 5px;
    display: flex;
    font-size: 14px;
    flex-direction: column;
}
.details{
    display: flex;
    font-size: 13px;
    line-height: 15px;
}
.stats_s_NO{
    color: white;
    width: 40px;
    min-width: 40px;
    background-color: red;
    padding-top: 10px;
    padding-bottom: 10px;
   
    border-radius: 5px 5px 5px 5px;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    line-height: 10px;
    font-size: 14px;
    font-family: "Inder";
    align-items: center;
    text-align: center;
}
.stats_s_OK{
    color: white;
    width: 40px;
    min-width: 40px;
    background-color: #33ce71;
    padding-top: 10px;
    padding-bottom: 10px;
   
    border-radius: 5px 5px 5px 5px;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    line-height: 10px;
    font-size: 14px;
    font-family: "Inder";
    align-items: center;
    text-align: center;
}
.stats_s_IP{
    color: white;
    width: 40px;
    min-width: 40px;
    background-color: #FF981F;
    padding-top: 10px;
    padding-bottom: 10px;
   
    border-radius: 5px 5px 5px 5px;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    line-height: 10px;
    font-size: 14px;
    font-family: "Inder";
    align-items: center;
    text-align: center;
}

/* border-color: red;
border-width: 2px;
border-style: solid ; */