.front-frame {
    width: 100vw;
    height: 100vh;
    display: flex;
    font-family: "Inder";
}

.frame-5 {
    color: #234081;
    width: 100%;
    height: 100%;
    background-color: #e0e6ec;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-top: 79px;
    padding-left: 5px;
    font-family: "Inder";
    font-size: 2rem;
    font-weight: 400;
    line-height: 40px;
    display: flex;
  }

  p { white-space: pre-wrap;}
  span { white-space: pre-wrap;}
  .guide1 {
    text-align: center;
    margin-top: 7px;
    font-size: 30px;
    font-weight: 400;
  }

  .guide2 {
      text-align: center;
      margin-bottom: 2px;
      font-size: 30px;
  }
  
  .flex-container {
    color: #000;
    width: 50%;
    margin-top: 2px;
    display: flex;
  }

  .flex-container-1 {
      flex-direction: row;
      align-items: center;
      justify-content: center;
  }

  /* .flex-container-1 img{
      margin: 20px;
  } */
/* 
  .breadth-title {
      left: 24%;
  }

  .breadth-block1 {
      position: absolute;
      left: 50%;
      top: 33%;
  }

  .breadth-block2 {
      position: absolute;
      left: 62%;
      top: 33%;
  }

  .breadth-block3 {
    position: absolute;
    left: 74%;
    top: 33%;
} */
  
  .breadth-track-requir {
    margin-right: 34px;
  }
  
  /* .flex-container-1 {
    height: 95%;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 2px;
    margin-left: 11px;
    display: flex;
  }
   */
  .rectangle-18, .rectangle-21 {
    width: 98px;
    height: 21px;
    object-fit: cover;
  }
  
  .rectangle-23 {
    width: 98px;
    height: 21px;
    object-fit: cover;
    margin-bottom: 2px;
  }
  
  .rectangle-25 {
    width: 98px;
    height: 21px;
    object-fit: cover;
    margin-top: 1px;
  }
  
  .flex-container-2 {
    height: 95%;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 2px;
    display: flex;
  }
  
  .rectangle-19, .rectangle-22 {
    width: 98px;
    height: 21px;
    object-fit: cover;
  }
  
  .rectangle-24 {
    width: 98px;
    height: 21px;
    object-fit: cover;
    margin-bottom: 1px;
  }
  
  .rectangle-26 {
    width: 98px;
    height: 21px;
    object-fit: cover;
  }
  
  .flex-container-3 {
    height: 95%;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 2px;
    display: flex;
  }
  
  .rectangle-20, .rectangle-27 {
    width: 98px;
    height: 21px;
    object-fit: cover;
  }
  
  .flex-container-4 {
    color: #000;
    width: 40%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 115px;
    display: flex;
  }
  
  .rectangle-28 {
    width: 98px;
    height: 21px;
    object-fit: cover;
    margin-bottom: 2px;
  }
  
  .you-can-use-this-to {
    text-align: center;
    margin-top: 24px;
    margin-bottom: 52px;
    margin-left: 3px;
  }
  
  .component-3 {
    text-align: center;
    color: #000;
    width: 20%;
    background-color: #c3d1de;
    border: 1px solid #527dec;
    flex-direction: row;
    flex-basis: 5%;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-left: 10px;
    padding-bottom: 1px;
    padding-right: 1px;
    font-family: Inder;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 30px;
    display: flex;
  }