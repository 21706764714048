.frame-17 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);

    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Inder;
    font-size: 1rem;
    font-weight: 400;
    line-height: 25px;
    
  }

  .frame-17-inner {
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 640px;
    background-color: #fff;
    border-radius: 4px;
  }

  .frame-17-inner .close-btn {
    position: absolute;
    border-radius: 4px;
    color: #fff;
    background-color: #527dec;
    border: 0;
    top: 16px;
    right: 16px;  
    height: 30px;
    width: 25px;
  }