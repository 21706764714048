.front-frame {
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: #e0e6ec;
}

.frame-10 {
    color: #000;
    width: 100%;
    height: 80%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 47px;
    padding-bottom: 23px;
    padding-left: 34px;
    font-family: Inder;
    
    font-weight: 400;
    line-height: 30px;
    display: flex;
}
  
.graduation-cap-svgre {
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-top: 7px;
}
  
.cmuda {
    font-family: Inter;
    font-size: 3rem;
    line-height: 58px;
}
  
.columbia-mscs-unoffi {
    text-align: center;
    font-size: 2.25rem;
    line-height: 35px;
}
  
.note-this-is-an-unof {
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 18px;
   
}
.alphawarning {
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 18px;
    color: red;
}
.flex-container {
    color: #0000008c;
    text-align: center;
    width: 65%;
    flex-direction: row;
    flex-basis: 6%;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 1px;
    margin-bottom: 15px;
    margin-left: 28px;
    display: flex;
}
  
.then-download-your-d {
    align-self: flex-end;
    font-size: 18px;
    line-height: 20px;
}
  
.vector {
    width: 25px;
    height: 25px;
    object-fit: cover;
}
  
.rectangle-7 {
    text-align: center;
    color: #00000070;
    width: 55%;
    background-color: #5d88f821;
    flex-direction: column;
    flex-basis: 21%;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    margin-bottom: 21px;
    margin-left: 4px;
    padding-right: 0px;
    padding-top: 25px;
    display: flex;
    border-radius: 5px;
}
  
.frame {
    width: 46px;
    height: 50px;
    object-fit: cover;
    margin-bottom: 17px;
    filter: invert(49%) sepia(10%) saturate(351%) hue-rotate(169deg) brightness(87%) contrast(87%);
    
}

.drag{
    font-size: 15px;
    line-height: 16px;
}
.drag-drop-your-file {
    height: 100%;
    width: 100%;
    margin-bottom: 5px;
}
  
.or-manually-input-yo {
    text-align: center;
    color: #0000008c;
    margin-top: 3px;
    margin-bottom: 13px;
    text-decoration: underline;
}

.or-manually-input-yo:hover {
    cursor: pointer;
}

.examplefile{
    cursor: pointer;
    font-size: 17px;
}