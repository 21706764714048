.title{
    width: 488px;
    align-self: flex-start;
    margin-bottom: 0px;
}

.frame-14{
    
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    width: 100%;
}

.flex-row{
    justify-content: space-between;
    
    margin-top: 50px;
    display: flex;
    width: 95%;
    height: 70px;
    
}

.edit{
    margin-bottom: 20px;
    margin-right: 10px;
    align-self: flex-end;
}

.choose{
    margin-right: 20px;
    align-self: flex-end;
}

/* .main_frame {
    background-color: #C3D1DE;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    flex-direction: row;
    display: flex;
    
    
} */
.main_frame{
  background-color: #C3D1DE;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  flex-direction: column;
  display: flex;
  font-family: 'Inder';
}
.main_frame::-webkit-scrollbar {
    display: none;
}
.flex-container-1 {
    
    width: 95%;
    flex-direction: column;
    justify-content: space-between;
    /* align-self: center; */
    align-items: stretch;
    margin-top: 1px;
    margin-bottom: 0px;
    /* margin-left: 1px; */
    font-size: 1.5rem;
    display: flex;
  }
  .rectangle-8 {
    color: #fff;
    width: 100%;
    line-height: 25px;
    background-color: #3d65bd;
    border-radius: 5px 5px 0 0;
    align-items: center;
    margin-bottom: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    font-size: 20px;
    display: flex;
  }
  .rectangle-82 {
    color: #fff;
    width: 100%;
    line-height: 60px;
    background-color: #FF981F;
    border-radius: 5px 5px 0 0;
    align-items: center;
    margin-bottom: 10px;
    padding-top: 1px;
    padding-left: 60px;
    font-size: 1.5rem;
    display: flex;
  }
  .rectangle-10 {
    color: #fff;
    width: 100%;
    background-color: #3d65bd;
    border-radius: 0 0 5px 5px;
    font-size: 1.5rem;
    display: flex;
  }
  .date-prepared-110622 {
    margin-bottom: 5px;
    margin-left: 15px;
    font-size: 16px;
  }
  .catalog-year-2021 {
    margin-left: 52px;
    margin-bottom: 2px;
    font-size: 16px;
  }
  .unoffical{
    color: red;
    margin-left: 15px;
    margin-bottom: 6px;
    font-size: 14px;
  }
  .overall {
    color: #fff;
    margin-top: 0px;
    margin-left: -10px;
    display: flex;
    font-size: 16px;
    line-height: 18px;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .flex-container-2 {
    width: 95%;
    flex-direction: row;
    justify-content: space-between;
    align-self: flex-end;
    align-items: flex-end;
    display: flex;
    
  }
  
  .overall_containerAt {
    background-color: #e62631;
    border-radius: 0 0 5px 5px;
    flex-direction: row;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
  }
  .overall_containerAll {
    background-color: #2aba63;
    border-radius: 0 0 5px 5px;
    flex-direction: row;
    
    width: 100%;
    
    display: flex;
    align-items: center;
  }
  .cat-absolute-container2 {
    background-color: #FF981F;
    border-radius: 0 0 5px 5px;
    flex-direction: row;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
  }
.error {
  height: 35px;
  width: 100px;
  display: flex;
  filter: brightness(0) invert(1);
  
}

.rectangle-9 {
    color: #000;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    
    font-size: 1.25rem;
    display: flex;
  }

  .flex-container-main{
    justify-content: space-between;
    flex-direction: column;
    margin-top: 30px;
    display: flex;
    width: 95%;
    height: 70px;
    margin-bottom: 20px;
    
    
}
.bottom{
    color: #C3D1DE;
}

.right_frame{
  margin-left: 85px;
}