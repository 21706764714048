/* .front-frame {
  width: 100vw;
  height: 100vh;
  display: flex;
}

.frame-10 {
  color: #000;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 25px;
  font-family: Inder;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 30px;
  display: flex;
}

.graduation-cap-svgre {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-top: 5px;
  margin-left: 5px;
}

.cmuda {
  margin-left: 1px;
  font-family: Inter;
  font-size: 3rem;
  line-height: 58px;
}

.columbia-mscs-unoffi {
  text-align: center;
  font-size: 2.25rem;
  line-height: 45px;
}

.note-this-is-an-unof {
  margin-bottom: 65px;
  line-height: 25px;
}

.to-run-the-mscs-degr {
  text-align: center;
  color: #000000e0;
  margin-top: 7px;
  margin-left: 1px;
  font-size: 1.5rem;
}

.component-6-instance-1 {
  width: 20%;
  flex-basis: 5%;
  margin-bottom: 106px;
  margin-left: 7px;
}

.component-3-instance-1 {
  width: 10%;
  flex-basis: 5%;
  margin-top: 12px;
  margin-bottom: 29px;
  margin-left: 13px;
} */

/* .Sidebar {
  height: 100%;
  width: 5%;
  background-color: white;
}

.nav-vector {
  margin-left: 33%;
  margin-top: 45px;
}

.SidebarList {
  height: auto;
  padding: 0;
  width: 100%;
}

.SidebarList .row {
  width: 100%;
  height: 80px;
  list-style-type: none;
  margin: 0%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.SidebarList .row:hover {
  cursor: pointer;
  background-color: aliceblue;
}

.SidebarList .row .row-title {
  justify-content: center;
  text-align: center;
  margin-left: 5%;
} */

.outer-menu {
  margin-top: 2rem;
  font-family: 'Inder'
}

.t{
  font-family: 'Inder';
}



.front-frame {
  white-space: pre-wrap;    
}