.rectangle-b{
    
}

.flex-container-item{
    color: #fff;
    
    width: 100%;
    line-height: 25px;
    min-height: 45px;
    background-color: #3d65bd;
    border-radius: 5px 5px 5px 5px;
    align-items: center;
    margin-bottom: 0px;
    padding-top: 1px;
    padding-left: 15px;
    font-size: 16px;
    display: flex;
}

.collapsible{
    width: 100%;
    margin-bottom: 10px;
}

.content{
    background-color: #C3D1DE;
    border-radius: 6px 6px 6px 6px;
    padding-left: 0px;
}

.c_icon{
    margin-left: auto; 
    margin-right: 20px;
    margin-bottom: 0px;
    margin-top: 0px;
    filter: brightness(0) invert(1);
    
}

@media only screen and (min-width: 600px){
.stats-Not{
    background-color: red;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 5px 5px 5px 5px;
    margin-right: 20px;
    line-height: 12px;
    font-size: 15px;
    min-width:135px;
    font-family: "Inder";
    white-space: nowrap;
}

.stats-yellow{
    background-color: #FF981F;
    min-width:135px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 5px 5px 5px 5px;
    margin-right: 20px;
    line-height: 12px;
    font-size: 15px;
    font-family: "Inder";
    white-space: nowrap;
}

.stats-Fulfilled{
    background-color: #33ce71;
    min-width:135px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 5px 5px 5px 5px;
    margin-right: 20px;
    line-height: 12px;
    font-size: 15px;
    font-family: "Inder";
    white-space: nowrap;
}}
@media only screen and (max-width: 600px){
.stats-Fulfilled{
    background-color: #33ce71;
    min-width:50px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 5px 5px 5px 5px;
    margin-right: 20px;
    line-height: 12px;
    font-size: 15px;
    font-family: "Inder";
    white-space: nowrap;
}
.stats-Not{
    background-color: red;
    min-width:50px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 5px 5px 5px 5px;
    margin-right: 20px;
    line-height: 12px;
    font-size: 15px;
    font-family: "Inder";
    white-space: nowrap;
}

}