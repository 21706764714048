

.frame_about1{
    
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    width: 100%;
}
.frame_about2{
    
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  
  width: 100%;
  align-self: flex-end;
}
.flex-center-bottom {
  align-self: flex-end;
}

.right_frame_about{
  margin-left: 85px;
  height: 100%;
  display: flex;
  
}
.columbia{
  height: 200px;
  width: 200px;
  margin-bottom: 20px;
  margin-right: 20px;
  display: flex;
  filter: brightness(0) invert(1);
}
.flex-center-bottom {
  justify-content: flex-end;
}